.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url(./fonts/Ubuntu/Ubuntu-Medium.ttf) format('truetype');
}

body p {
  margin: 0;
  box-sizing: border-box;
  padding: 10px;
}

body h4 {
  margin: 0;
  padding: 10px;
  font-size: 30px;
}

.intro {
  background-image: url('/src/assets/giphy.gif');
  background-size: cover;
  background-attachment: fixed;
  height:100%;
  width:100%;
  background-repeat: repeat-y;
  font-family: 'Ubuntu', sans-serif;
  color: white;
  text-align: center;
  box-sizing: border-box;
  padding: 50px;
  height: 100vh;
  display: table;
}

.name {
  font-size: 65px;
  margin: 0;
  padding: 10px;
  color: rgb(1, 1, 112);
}

.intro .building {
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  font-size: 25px;
}

.intro .building::after {
  content: attr(data-end);
  font-size: 25px;
}

.fade-in {
  transition: opacity 1s ease;  
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}

.btnBox {
  padding: 10px;
}

.btn-color {
  background-color: #8D94BA;
}

.hoverEffect:hover {
  background-color: #9A7AA0;

}

.intro .hoverEffect:hover {
  background-color: rgb(1, 1, 112);
}

.name-stuff {
  color: #84DCC6;
}

@media (min-width: 768px) {
  .navbar {
    background-color: #95A3B3;
  }
}

@media (max-width: 768px) {
  .navbar-btn {
    background-color: #95A3B3;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    margin: 15px;
    padding: 10px;
  } 
}

.logo-image {
  height: 100px;
  width: 100px;
  object-fit: contain;
}

.exp-box {
  margin: 10px;
  outline-color: #95A3B3;
  outline-style: solid;
  outline-width: 5px;
}

.w-100 {
  width: 100% !important;
  height: 95vh;
}

.circular-image {
    border-radius: 50%;
    width: 150px; 
    height: 150px; 
    object-fit: cover; 
}

.my-pic {
  height: 200px;
  width: 200px;
}

img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.resume-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin: 10px;
  gap: 20px;
}

.second-page {
  background-color: #282c34;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  font-family: 'Ubuntu', sans-serif;
  color: white;
  text-align: center;
  box-sizing: border-box;
  padding: 50px;
}

.footer {
  background-color: #95A3B3;
  display: flex;
  justify-content: flex-end;
  color: white;
  padding: 5px;
}

.flex-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-right: 10px;
}

.icon {
  color: white;
}

.built-by {
  font-size: 11px;
}

@media (max-width: 768px) {
  .video-responsive {
    position: relative;
    padding-bottom: 56.25%; /* Aspect ratio of 16:9 (height 9 / width 16 = 0.5625) */
    height: 0;
    overflow: hidden;
    display: flex;
    justify-content: center; /* Center horizontally */
  }
}

@media (max-width: 768px) {
  .video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Full width of the container */
    height: 100%; /* Full height of the container */
  }
}

.video-responsive {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center; /* Center horizontally */
}